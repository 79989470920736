import { IngredientCategory } from '@/types/ingredientCategory';
import { api } from './api';

const baseUrl = '/ingredient-categories';

const ingredientCategoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIngredientCategories: builder.query<
      { ingredientCategories: IngredientCategory[] },
      void
    >({
      query: () => `${baseUrl}`,
      providesTags: ['IngredientCategory'],
    }),
    getIngredientCategoryById: builder.query<
      { ingredientCategory: IngredientCategory },
      IngredientCategory['id']
    >({
      query: (ingredientCategoryId) => `${baseUrl}/${ingredientCategoryId}`,
      providesTags: ['IngredientCategory'],
    }),
    createIngredientCategory: builder.mutation<
      { ingredientCategory: IngredientCategory },
      { name: string }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['IngredientCategory'],
    }),
    updateIngredientCategory: builder.mutation<
      { ingredientCategory: IngredientCategory },
      { ingredientCategoryId: IngredientCategory['id']; name: string }
    >({
      query: ({ ingredientCategoryId, name }) => ({
        url: `${baseUrl}/${ingredientCategoryId}`,
        method: 'PATCH',
        body: { name },
      }),
      invalidatesTags: ['IngredientCategory'],
    }),
    deleteIngredientCategory: builder.mutation<
      { ingredientCategory: IngredientCategory },
      { ingredientCategoryId: IngredientCategory['id'] }
    >({
      query: ({ ingredientCategoryId }) => ({
        url: `${baseUrl}/${ingredientCategoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IngredientCategory'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = ingredientCategoriesApi.endpoints;
export const {
  useGetIngredientCategoriesQuery,
  useGetIngredientCategoryByIdQuery,
  useCreateIngredientCategoryMutation,
  useUpdateIngredientCategoryMutation,
  useDeleteIngredientCategoryMutation,
} = ingredientCategoriesApi;
