export const daysList: string[] = [
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
  'Niedziela',
];

export enum DaysList {
  MONDAY = 'Poniedziałek',
  TUESDAY = 'Wtorek',
  WEDNESDAY = 'Środa',
  THURSDAY = 'Czwartek',
  FRIDAY = 'Piątek',
  SATURDAY = 'Sobota',
  SUNDAY = 'Niedziela',
}
