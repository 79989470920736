import { MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { useRejectOrderMutation } from '@/api/orders';
import { Order, OrderStatus } from '@/types/order';

export const OrderActions = ({ order }: { order: Order }) => {
  const [rejectOrder] = useRejectOrderMutation();

  return (
    <div className="text-right relative">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="w-48 text-slate-900 text-sm font-medium leading-tight"
        >
          <DropdownMenuItem
            disabled
            className="text-slate-500 text-xs font-semibold leading-none"
          >
            Opcje
          </DropdownMenuItem>
          {[OrderStatus.NEW, OrderStatus.REALIZATION].includes(
            order.status,
          ) && (
            <ConfirmationModal
              title="Anuluj zamówienie"
              description="Czy na pewno chcesz anulować to zamówienie?"
              onConfirm={() => rejectOrder({ orderId: order.id })}
            >
              <Button
                variant="ghost"
                className="px-2 w-full justify-start text-red-500 hover:text-red-500"
              >
                <Trash className="w-4 h-4 mr-1" />
                Anuluj zamówienie
              </Button>
            </ConfirmationModal>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
