import { useGetIngredientsQuery } from '@/api/ingredients';
import { DataTable } from '../DataTable';
import { NotFound } from '../NotFound';
import { AddEditIngredient } from './AddEditIngredient';
import { columns } from './columns';

export const Ingredients = () => {
  const { data, isLoading } = useGetIngredientsQuery();
  const ingredients = data?.ingredients?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista składników
        </h1>
        {!!ingredients && <AddEditIngredient light />}
      </div>

      {!!ingredients ? (
        <DataTable columns={columns} data={data.ingredients} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnego składnika"
          element={<AddEditIngredient />}
        />
      )}
    </>
  );
};
