import { Ingredient } from '@/types/ingredient';
import { Pizza } from '@/types/pizza';
import { Size } from '@/types/size';
import { api } from './api';

export type PizzaSizePrice = {
  sizeId: Size['id'];
  price: number;
  name?: string;
};

const baseUrl = '/pizzas';

const pizzaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPizzas: builder.query<{ pizzas: Pizza[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Pizza'],
    }),
    createPizza: builder.mutation<
      { pizza: Pizza },
      {
        name: Pizza['name'];
        available?: Pizza['available'];
        isHalfByHalfAvailable?: Pizza['isHalfByHalfAvailable'];
        ingredientsIds: Ingredient['id'][];
        pizzaSizePrices: PizzaSizePrice[];
      }
    >({
      query: ({
        name,
        available,
        isHalfByHalfAvailable,
        ingredientsIds,
        pizzaSizePrices,
      }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: {
          name,
          available,
          isHalfByHalfAvailable,
          ingredientsIds,
          pizzaSizePrices,
        },
      }),
      invalidatesTags: ['Pizza'],
    }),
    updatePizza: builder.mutation<
      { pizza: Pizza },
      {
        pizzaId: Pizza['id'];
        name?: Pizza['name'];
        available?: Pizza['available'];
        isHalfByHalfAvailable?: Pizza['isHalfByHalfAvailable'];
        ingredientsIds?: Ingredient['id'][];
        pizzaSizePrices?: PizzaSizePrice[];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.pizzaId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Pizza'],
    }),
    togglePizzaAvailability: builder.mutation<
      { pizza: Pizza },
      { pizzaId: Pizza['id'] }
    >({
      query: ({ pizzaId }) => ({
        url: `${baseUrl}/${pizzaId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Pizza'],
    }),
    deletePizza: builder.mutation<{ pizza: Pizza }, { pizzaId: Pizza['id'] }>({
      query: ({ pizzaId }) => ({
        url: `${baseUrl}/${pizzaId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Pizza'],
    }),
    markAsBasePizza: builder.mutation<
      { pizza: Pizza },
      { pizzaId: Pizza['id'] }
    >({
      query: ({ pizzaId }) => ({
        url: `${baseUrl}/${pizzaId}/mark-as-base`,
        method: 'PUT',
      }),
      invalidatesTags: ['Pizza'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = pizzaApi.endpoints;
export const {
  useDeletePizzaMutation,
  useGetPizzasQuery,
  useCreatePizzaMutation,
  useUpdatePizzaMutation,
  useTogglePizzaAvailabilityMutation,
  useMarkAsBasePizzaMutation,
} = pizzaApi;
