import { useGetProductCategoriesQuery } from '@/api/product-category';
import { DataTable } from '../DataTable';
import { NotFound } from '../NotFound';
import { AddEditProductCategory } from './AddEditProductCategory';
import { columns } from './columns';

export const ProductCategory = () => {
  const { data, isLoading } = useGetProductCategoriesQuery();
  const productCategories = data?.productCategories?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista kategorii produktów
        </h1>
        {!!productCategories && <AddEditProductCategory light />}
      </div>

      {!!productCategories ? (
        <DataTable columns={columns} data={data.productCategories} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnej kategorii produktu"
          element={<AddEditProductCategory />}
        />
      )}
    </>
  );
};
