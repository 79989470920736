import { Order, OrderStatus } from '@/types/order';
import { api } from './api';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeOrderStatus: builder.mutation<
      { order: Order },
      { orderId: string; status: OrderStatus }
    >({
      invalidatesTags: ['Order'],
      query: ({ orderId, status }) => ({
        url: `/orders/${orderId}/status`,
        method: 'PUT',
        body: { status },
      }),
    }),
    findOrdersByStatus: builder.query<
      { orders: Order[] },
      { status: OrderStatus }
    >({
      providesTags: ['Order'],
      query: ({ status }) => `/orders?status=${status}`,
    }),

    rejectOrder: builder.mutation<{ order: Order }, { orderId: Order['id'] }>({
      invalidatesTags: ['Order'],
      query: ({ orderId }) => ({
        url: `/orders/${orderId}/reject`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const eventsApiEndpoints = ordersApi.endpoints;
export const {
  useChangeOrderStatusMutation,
  useFindOrdersByStatusQuery,
  useRejectOrderMutation,
} = ordersApi;
