import { SettingsType, useGetSettingsByTypeQuery } from '@/api/settings';
import { SettingSwitch } from './SettingSwitch';

export const IsPizzeriaOpened = () => {
  const { data } = useGetSettingsByTypeQuery({
    type: SettingsType.GENERAL,
    name: 'isPizzeriaOpened',
  });

  return (
    <div>
      {data?.settings.map((setting) => (
        <SettingSwitch key={setting.id} item={setting} />
      ))}
    </div>
  );
};
