import { Ingredient } from '@/types/ingredient';
import { IngredientCategory } from '@/types/ingredientCategory';
import { api } from './api';
import { PizzaSizePrice } from './pizza';

const baseUrl = '/ingredients';

const ingredientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIngredients: builder.query<{ ingredients: Ingredient[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Ingredient'],
    }),
    createIngredient: builder.mutation<
      { ingredient: Ingredient },
      {
        name: string;
        available: Ingredient['available'];
        sizePrices: PizzaSizePrice[];
        ingredientCategoryId: IngredientCategory['id'];
      }
    >({
      query: (body) => {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Ingredient'],
    }),
    updateIngredient: builder.mutation<
      { ingredient: Ingredient },
      {
        name: string;
        sizePrices: PizzaSizePrice[];
        available: Ingredient['available'];
        ingredientCategoryId: IngredientCategory['id'];
        ingredientId: Ingredient['id'];
      }
    >({
      query: ({ ingredientId, ...body }) => ({
        url: `${baseUrl}/${ingredientId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Ingredient'],
    }),
    deleteIngredient: builder.mutation<
      { ingredient: Ingredient },
      { ingredientId: Ingredient['id'] }
    >({
      query: ({ ingredientId }) => ({
        url: `${baseUrl}/${ingredientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ingredient'],
    }),
    toggleIngredientAvailability: builder.mutation<
      { ingredient: Ingredient },
      { ingredientId: Ingredient['id'] }
    >({
      query: ({ ingredientId }) => ({
        url: `${baseUrl}/${ingredientId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Ingredient'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = ingredientsApi.endpoints;
export const {
  useGetIngredientsQuery,
  useCreateIngredientMutation,
  useUpdateIngredientMutation,
  useDeleteIngredientMutation,
  useToggleIngredientAvailabilityMutation,
} = ingredientsApi;
