import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { Outlet, useNavigate } from 'react-router-dom';

type TabValue = 'produkty' | 'kategorie-produktów';

export const ProductView = () => {
  const navigate = useNavigate();
  const handleTabClick = (value: TabValue) => navigate(`/produkty/${value}`);

  return (
    <div className="flex flex-col">
      <Tabs defaultValue="products" className="w-full">
        <TabsList className="px-10 pt-10 flex">
          <TabsTrigger
            value="products"
            onClick={() => handleTabClick('produkty')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Produkty
          </TabsTrigger>
          <TabsTrigger
            value="productCategory"
            onClick={() => handleTabClick('kategorie-produktów')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Kategorie produktów
          </TabsTrigger>
        </TabsList>
        <TabsContent value="products">
          <Outlet />
        </TabsContent>
        <TabsContent value="productCategory">
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  );
};
