import { userEndpoints } from '@/api/users';
import { User } from '@/types/user';
import { createSlice } from '@reduxjs/toolkit';
import Cookies, { CookieAttributes } from 'js-cookie';

export type CredentialsStore = {
  accessToken: string | null;
  auth0Token: string | null;
  user?: User;
};

const COOKIE_NAME = 'access_token';
const COOKIE_CONFIG: CookieAttributes = {
  // domain: 'admin.dramoff.com',
  domain: "null",
  expires: 1 / 24,
  sameSite: 'Strict',
};

const accessToken = Cookies.get(COOKIE_NAME);

const initialState: CredentialsStore = {
  accessToken: accessToken || null,
  auth0Token: accessToken || null,
  user: undefined,
};

const credentialsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(
      userEndpoints.getCurrentUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
      },
    );
  },
  name: 'credentials',
  initialState,
  reducers: {
    login: (state, action) => {
      if (action.payload !== undefined) {
        const { accessToken, auth0Token } = action.payload;
        state.accessToken = accessToken;
        state.auth0Token = auth0Token;
        Cookies.set(COOKIE_NAME, accessToken, COOKIE_CONFIG);
      }
    },
    logout: (state) => {
      state.accessToken = null;
      state.auth0Token = null;
      state.user = undefined;

      Cookies.remove(COOKIE_NAME, COOKIE_CONFIG);
    },
  },
});

export const { logout, login } = credentialsSlice.actions;
export const credentialsReducer = credentialsSlice.reducer;
