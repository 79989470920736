import { useFindOrdersByStatusQuery } from '@/api/orders';
import { OrderStatus } from '@/types/order';
import { Fragment, useEffect } from 'react';
import { OrderItem } from './OrderItem';

export const OrdersView = () => {
  const orderStatuses = [
    { status: OrderStatus.NEW, title: 'Do realizacji' },
    { status: OrderStatus.REALIZATION, title: 'W realizacji' },
    {
      status: OrderStatus.IN_DELIVERY,
      title: 'W dostarczeniu <br/> (Gotowe do odbioru)',
    },
    { status: OrderStatus.READY, title: 'Zakończone' },
  ];

  return (
    <div className="flex flex-col mb-12 w-full">
      <div className="hidden 2xl:grid grid-cols-4 gap-x-1 text-center mt-12 mb-7.5 2xl:mx-10">
        {orderStatuses.map(({ title }) => (
          <h1
            dangerouslySetInnerHTML={{ __html: title }}
            className="text-2xl font-semibold leading-loose"
            key={title}
          ></h1>
        ))}
      </div>
      <div className="grid grid-cols-1 2xl:grid-cols-4 gap-x-1 2xl:gap-y-10 ml-10 2xl:mx-10">
        {orderStatuses.map(({ status, title }) => {
          const { data, refetch } = useFindOrdersByStatusQuery({ status });

          useEffect(() => {
            const interval = setInterval(() => {
              refetch();
            }, 10000);

            return () => clearInterval(interval);
          }, [refetch]);

          return (
            <Fragment key={status}>
              <h1
                dangerouslySetInnerHTML={{ __html: title }}
                className="text-2xl font-semibold leading-loose mt-10 mb-2 2xl:hidden"
              ></h1>
              <div className="flex 2xl:flex-col gap-5 overflow-x-auto max-w-[100vw] 2xl:overflow-y-auto 2xl:overflow-x-hidden 2xl:max-h-[calc(100vh-195px)]">
                {data?.orders.map((order) => (
                  <OrderItem key={order.id} order={order} />
                ))}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
