import { useDeleteSizeMutation } from '@/api/sizes';
import { Size } from '@/types/size';
import { trimId } from '@/utils/trimId';
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenString } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import { DropdownMenuContent, DropdownMenuItem } from '../ui/dropdown-menu';
import { AddEditSize } from './AddEditSize';

export const columns: ColumnDef<Size>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      return (
        <ShortenString
          content={row.original.id}
          argName="ID"
          trimCallback={trimId}
        />
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'description',
    header: 'Opis',
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteSize] = useDeleteSizeMutation();
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <AddEditSize type="update" size={row.original} />
              <ConfirmationModal
                title="Delete"
                description="Delete message"
                onConfirm={() => deleteSize({ sizeId: row.original.id! })}
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
