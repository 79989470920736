import { useGetProductsQuery } from '@/api/product';
import { DataTable } from '../DataTable';
import { NotFound } from '../NotFound';
import { AddEditProducts } from './AddEditProducts';
import { columns } from './columns';

export const Products = () => {
  const { data, isLoading } = useGetProductsQuery();
  const products = data?.products?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista produktów
        </h1>
        {!!products && <AddEditProducts light />}
      </div>

      {!!products ? (
        <DataTable columns={columns} data={data.products} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnego produktu"
          element={<AddEditProducts />}
        />
      )}
    </>
  );
};
