import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
// import { useGetCurrentUserQuery } from './api/users';
import { Menu } from './components/menu/Menu';
// import { useIsUserLoggedIn } from './slices/credentials/selectors';

export function App(): ReactElement {
  // const isLoggedIn = useIsUserLoggedIn();
  // useGetCurrentUserQuery(undefined, { skip: !isLoggedIn });

  return (
    <ProtectedRoute>
      <div className="bg-slate-50 flex flex-col min-h-screen w-screen">
        <Menu />
        <Outlet />
      </div>
    </ProtectedRoute>
  );
}
