import { api } from '@/api/api';
import { logout as logoutReducer } from '@/slices/credentials/credentialsSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { logout: auth0Logout } = useAuth0();

  const logout = async (state?: string) => {
    dispatch(api.util.resetApiState());
    dispatch(logoutReducer());
    await auth0Logout({
      logoutParams: {
        returnTo: `${import.meta.env.VITE_AUTH0_LOGOUT_REDIRECT_URI}${state ? `?state=${state}` : ''}`,
      },
    });
  };

  return logout;

  // return useCallback(async () => {
  //   dispatch(api.util.resetApiState());
  //   dispatch(logoutReducer());
  //   await auth0Logout({
  //     logoutParams: {
  //       returnTo: `${import.meta.env.VITE_AUTH0_LOGOUT_REDIRECT_URI}${state ? `?state=${state}` : ''}`,
  //     },
  //   });
  // }, [dispatch, isAuthenticated]);
};
