import { Setting, useUpdateSettingMutation } from '@/api/settings';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';
import { DialogFooter } from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';

const Schema = yup.object({
  value: yup.string().max(60),
  id: yup.string().max(60),
});

type Props = {
  item: Setting;
};

export const SettingForm = ({ item }: Props) => {
  const [updateSetting] = useUpdateSettingMutation();

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      value: item.value,
      id: item.id,
    },
  });

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) return;

      const settingId = values.id ?? '';
      const value = values.value ?? '';

      updateSetting({ settingId, value });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    await form.handleSubmit(onSubmit)();
  };

  return (
    <Form {...form}>
      <form className="w-full max-w-100 px-10 pt-10 flex items-end gap-x-2">
        <FormField
          control={form.control}
          name="value"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>{item.name}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="sm:justify-end">
          <ConfirmationModal
            title="Czy jesteś pewny?"
            description="Zmiana ustawienia spowoduje nieodwracalne zmiany w systemie."
            onConfirm={handleConfirm}
          >
            <Button className="bg-slate-900 text-white mt-4">Zapisz</Button>
          </ConfirmationModal>
        </DialogFooter>
      </form>
    </Form>
  );
};
