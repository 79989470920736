import { ProductCategory } from '@/types/productCategory';
import { api } from './api';

const baseUrl = '/product-categories';

const productCategoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProductCategories: builder.query<
      { productCategories: ProductCategory[] },
      void
    >({
      query: () => `${baseUrl}`,
      providesTags: ['ProductCategory'],
    }),
    getProductCategoryById: builder.query<
      { productCategory: ProductCategory },
      string
    >({
      query: (productCategoryId) => `${baseUrl}/${productCategoryId}`,
      providesTags: ['ProductCategory'],
    }),
    createProductCategory: builder.mutation<
      { productCategory: ProductCategory },
      {
        name: ProductCategory['name'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProductCategory'],
    }),
    updateProductCategory: builder.mutation<
      { productCategory: ProductCategory },
      {
        productCategoryId: ProductCategory['id'];
        name: ProductCategory['name'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.productCategoryId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ProductCategory'],
    }),
    deleteProductCategory: builder.mutation<
      { productCategory: ProductCategory },
      { productCategoryId: ProductCategory['id'] }
    >({
      query: ({ productCategoryId }) => ({
        url: `${baseUrl}/${productCategoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProductCategory'],
    }),
  }),
  overrideExisting: false,
});

export const productCategoryEndpoints = productCategoriesApi.endpoints;
export const {
  useGetProductCategoriesQuery,
  useGetProductCategoryByIdQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
} = productCategoriesApi;
