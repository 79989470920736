import {
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
} from '@/api/product-category';
import { ProductCategory } from '@/types/productCategory';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Plus } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';

const Schema = yup.object({
  name: yup.string().max(60, 'Nazwa może zawierać maksymalnie 60 znaków'),
  description: yup.string(),
  available: yup.boolean(),
  price: yup.number(),
  quantity: yup.number(),
});

type Props = {
  light?: boolean;
  type?: 'update';
  productCategory?: ProductCategory;
};

export const AddEditProductCategory = ({
  light,
  type,
  productCategory,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [createProductCategory] = useCreateProductCategoryMutation();
  const [updateProductCategory] = useUpdateProductCategoryMutation();

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: productCategory?.name || '',
    },
  });

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) {
        return;
      }

      if (type === 'update') {
        await updateProductCategory({
          ...values,
          productCategoryId: productCategory?.id!,
          name: values.name || '',
        });
      } else {
        await createProductCategory({
          ...values,
          name: values.name || '',
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">
              Dodaj kategorię produktu
            </p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update'
              ? 'Edytuj kategorię produktu'
              : 'Dodaj kategorię produktu'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz kategorię produktu
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
