import { useGetIngredientsQuery } from '@/api/ingredients';
import {
  PizzaSizePrice,
  useCreatePizzaMutation,
  useUpdatePizzaMutation,
} from '@/api/pizza';
import { useGetSizesQuery } from '@/api/sizes';
import { Ingredient } from '@/types/ingredient';
import { Pizza } from '@/types/pizza';
import { Size } from '@/types/size';
import { yupResolver } from '@hookform/resolvers/yup';
import { Edit, Info, Plus } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

const Schema = yup.object({
  name: yup.string().required(),
  available: yup.boolean().optional().default(false),
  pizzaSizePrice: yup.array().of(
    yup.object().shape({
      sizeId: yup.string().required(),
      price: yup.number().required(),
    }),
  ),
  ingredientsIds: yup.array().of(yup.string()),
});

type Props = {
  light?: boolean;
  type?: 'update';
  pizza?: Pizza;
  closeDropdown?: () => void;
};

export const AddEditPizza = ({
  light,
  type,
  pizza,
  closeDropdown,
}: Props): ReactElement => {
  const ingredientsIds = pizza?.ingredients.map(({ id }) => id) ?? [];
  const [formIngredients, setFormIngredients] =
    useState<Ingredient['id'][]>(ingredientsIds);

  const [open, setOpen] = useState(false);
  const [pizzaSizePrices, setPizzaSizePrices] = useState<PizzaSizePrice[]>([]);
  const [createPizza] = useCreatePizzaMutation();
  const [updatePizza] = useUpdatePizzaMutation();
  const { data: sizes } = useGetSizesQuery();
  const { data: ingredients } = useGetIngredientsQuery();

  const isUpdate = type === 'update';

  const currentPizzaSizePrices = pizza?.pizzaSizePrice.map((sizePrice) => ({
    sizeId: sizePrice.size.id,
    price: sizePrice.price,
  }));

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: pizza ? pizza.name : '',
      available: !!pizza?.available,
      ingredientsIds: pizza
        ? ingredients?.ingredients.map(({ id }) => id) ?? []
        : [],
      pizzaSizePrice: currentPizzaSizePrices,
    },
  });

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) {
        return;
      }

      if (type === 'update') {
        await updatePizza({
          pizzaId: pizza?.id!,
          name: values.name,
          available: values.available,
          ingredientsIds: formIngredients,
          pizzaSizePrices,
        });
        closeDropdown && closeDropdown();
      } else {
        await createPizza({
          name: values.name ?? '',
          available: values.available,
          ingredientsIds: formIngredients,
          pizzaSizePrices,
        });
      }

      form.reset();
      setFormIngredients([]);

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <Edit className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj pizzę</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj pizzę' : 'Dodaj pizzę'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <div className="flex items-end gap-x-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Nazwa pizzy</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <TooltipProvider>
                  <Tooltip>
                    <div className="flex flex-col items-center gap-1">
                      <TooltipTrigger asChild className="px-2.5">
                        <div className="flex items-center">
                          <p className="text-sm">Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                      </TooltipTrigger>
                      <Switch
                        checked={form.watch('available')}
                        onCheckedChange={(value) =>
                          form.setValue('available', value)
                        }
                      />
                    </div>
                    <TooltipContent>
                      <p className="text-xs leading-tight">
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        pizzy
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div>
                <div className="flex justify-between">
                  <p className="text-sm leading-tight">Rozmiar</p>
                  <p className="w-40 text-sm leading-tight">Cena</p>
                </div>
                {sizes?.pizzaSizes.map(({ name, id }) => {
                  const setSizePrice = (sizeId: string, price: number) => {
                    setPizzaSizePrices((prev) => {
                      if (
                        !prev.find((pizzaSize) => pizzaSize.sizeId === sizeId)
                      )
                        return [...prev, { price, sizeId }];

                      return prev.map((pizzaSize) => {
                        if (pizzaSize?.sizeId === sizeId)
                          return {
                            ...pizzaSize,
                            price,
                          };

                        return pizzaSize;
                      });
                    });
                  };

                  const getPizzaPriceBySizeId = (sizeId: Size['id']) => {
                    const price = pizza?.pizzaSizePrice.find(
                      ({ size }) => size.id === sizeId,
                    );

                    return price?.price;
                  };

                  return (
                    <div key={id}>
                      <FormField
                        name="pizzaSizePrice"
                        control={form.control}
                        render={() => (
                          <FormItem className="flex items-center justify-between">
                            <FormLabel className="text-sm">{name}</FormLabel>
                            <FormControl className="w-40">
                              <Input
                                placeholder="Cena"
                                defaultValue={getPizzaPriceBySizeId(id)}
                                onChange={(e) =>
                                  setSizePrice(id, Number(e.target.value))
                                }
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  );
                })}
              </div>
              <hr />
              <div>
                <p className="text-sm leading-tight mb-1">Składniki</p>
                <div className="max-h-24 overflow-y-auto flex flex-col gap-y-1">
                  {ingredients?.ingredients
                    .map(
                      ({
                        name,
                        id,
                      }): { name: string; id: string; checked: boolean } => {
                        const isIngredientChecked = (
                          ingredientId: Ingredient['id'],
                        ): boolean => {
                          if (!pizza?.ingredients?.length) return false;

                          const ingredientsIds = pizza.ingredients.map(
                            ({ id }) => id,
                          );
                          return ingredientsIds?.includes(ingredientId);
                        };
                        return {
                          name,
                          id,
                          checked: isIngredientChecked(id),
                        };
                      },
                    )
                    .sort((a, b) => (a.checked ? -1 : 1))
                    .map(({ name, id, checked }) => {
                      const toggleIngredient = (ingredientId: string) => {
                        setFormIngredients((prev) => {
                          if (prev.includes(ingredientId)) {
                            return prev.filter((id) => id !== ingredientId);
                          }
                          return [...prev, ingredientId];
                        });
                      };

                      return (
                        <div key={id}>
                          <FormField
                            control={form.control}
                            name="ingredientsIds"
                            render={({ field }) => (
                              <div className="flex items-center space-x-1">
                                <Checkbox
                                  defaultChecked={checked}
                                  onCheckedChange={() => toggleIngredient(id)}
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  {name}
                                </label>
                              </div>
                            )}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz pizzę
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
