import { useCreateUserMutation, useUpdateUserMutation } from '@/api/users';
import { useCurrentUser } from '@/slices/credentials/selectors';
import { User } from '@/types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Files, Plus, Trash } from 'lucide-react';
import { ChangeEvent, ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';

const schema = yup.object({
  firstName: yup.string().required('Imię jest wymagane'),
  lastName: yup.string().required('Nazwisko jest wymagane'),
  email: yup.string(),
  avatarPath: yup.string(),
});

type Props = {
  type?: 'update';
  user?: User;
};

export const AddEditUser = ({ type, user }: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [avatarImage, setAvatarImage] = useState<string | null>(null);
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const currentUser = useCurrentUser();

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      avatarPath: user?.avatarPath || '',
    },
  });

  const { handleSubmit, reset } = form;
  const onSubmit = async (values: any) => {
    try {
      if (type === 'update') {
        await updateUser({
          userId: user!.id,
          user: values,
        });
      } else {
        await createUser({
          user: values,
        });
      }

      setOpen(false);
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeAvatar = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    const fileUrl = file ? URL.createObjectURL(file) : null;
    if (file) {
      setAvatarImage(fileUrl);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button className="bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900 h-8 px-2">
            <Plus className="size-4 mr-1 text-slate-500" />
            <p className="text-sm font-medium leading-normal">
              Dodaj użytkownika
            </p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Imię</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwisko</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {!isUpdate && (
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>E-mail</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {/* <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Adres e-mail</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div>
                <p className="text-sm font-medium leading-tight mb-2">Rola</p>
                <Tabs defaultValue={user?.role?.toUpperCase() || 'MANAGER'}>
                  <TabsList>
                    <TabsTrigger
                      value="MANAGER"
                      onClick={() => form.setValue('role', 'manager')}
                    >
                      Manager
                    </TabsTrigger>
                    <TabsTrigger
                      value="ADMIN"
                      onClick={() => form.setValue('role', 'admin')}
                    >
                      Administrator
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div> */}
              {/* {currentUser?.id === user?.id && ( */}
              <>
                {/* <div>
                    <label className="text-sm font-medium leading-tight mt-6">
                      Awatar
                    </label>
                    <label
                      htmlFor="avatarImage"
                      className="flex items-center justify-center mt-1.5 w-full py-8 bg-slate-50 rounded border border-dashed border-slate-400 cursor-pointer"
                    >
                      <div className="flex flex-col justify-center items-center gap-y-4">
                        <Files className="w-16 h-16" />
                        <p className="text-center">
                          Przeciągnij i umieść plik tutaj, lub{' '}
                          <span className="font-medium underline underline-offset-4">
                            wybierz plik
                          </span>
                        </p>
                      </div>
                    </label>
                    <Input
                      id="avatarImage"
                      name="avatarImage"
                      type="file"
                      accept="image/png, image/jpeg, image/svg+xml, image/webp, image/avif"
                      className="sr-only"
                      onChange={handleChangeAvatar}
                    />
                  </div> */}
                {/* <p className="text-sm font-medium leading-tight mt-4 mb-2">
                    {avatarImage && 'Wybrane zdjęcie:'}
                  </p>
                  {avatarImage && (
                    <div className="relative group size-24">
                      <img
                        src={avatarImage}
                        alt="Uploaded image"
                        className="object-cover size-24 rounded"
                      />
                      <Button
                        className="hidden group-hover:flex justify-center items-center size-full absolute left-0 top-0 bg-transparent hover:bg-red-500/50 duration-200"
                        onClick={() => setAvatarImage(null)}
                        type="button"
                      >
                        <Trash className="w-4 h-4" />
                      </Button>
                    </div>
                  )} */}
              </>
              {/* )} */}
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz użytkownika
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
