import {
  useDeletePizzaMutation,
  useMarkAsBasePizzaMutation,
  useTogglePizzaAvailabilityMutation,
  useUpdatePizzaMutation,
} from '@/api/pizza';
import { Pizza } from '@/types/pizza';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import { CircleDot, Info, MoreHorizontal, Trash } from 'lucide-react';
import { useState } from 'react';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenString } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Input } from '../ui/input';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { AddEditPizza } from './AddEditPizza';

export const columns: ColumnDef<Pizza>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => {
      return (
        <ShortenString
          content={row.original.id}
          argName="ID"
          trimCallback={trimId}
        />
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'available',
    header: 'Dostępność',
    cell: ({ row }) => {
      return (
        <p
          className={clsx(
            row.original.available
              ? 'border-emerald-600 text-emerald-600'
              : 'border-red-400 text-red-500',
            'inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1',
          )}
        >
          <CircleDot className="w-2.5 h-2.5" />{' '}
          {row.original.available ? 'Dostępny' : 'Niedostępny'}
        </p>
      );
    },
  },
  {
    accessorKey: 'base',
    header: 'Baza',
    cell: ({ row }) => {
      const [markAsBasePizza] = useMarkAsBasePizzaMutation();

      return (
        <Input
          type="radio"
          checked={row.original.isBase}
          onChange={(isChecked) =>
            !row.original.isBase &&
            isChecked &&
            markAsBasePizza({ pizzaId: row.original.id })
          }
          className="size-5 ml-1.5 accent-gray-800"
          aria-label="Select row"
        />
      );
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      // const [isAvailable, setIsAvailable] = useState(
      //   !!row?.original?.available,
      // );

      // const [isHalfByHalfAvailable, setIsHalfByHalfAvailable] = useState(
      //   !!row?.original?.isHalfByHalfAvailable,
      // );

      const [updatePizza] = useUpdatePizzaMutation();
      const [deletePizza] = useDeletePizzaMutation();
      const [togglePizzaAvailability] = useTogglePizzaAvailabilityMutation();

      const toggleAvailability = async () => {
        try {
          // setIsAvailable((prev) => !prev);
          await togglePizzaAvailability({ pizzaId: row.original.id });
        } catch (error) {
          console.error(error);
        }
      };

      const onUpdatePizza = async (data: Pizza) => {
        await updatePizza({
          pizzaId: data.id,
          available: data.available,
          ingredientsIds: data.ingredients.map((ing) => ing.id),
          isHalfByHalfAvailable: data.isHalfByHalfAvailable,
          name: data.name,
        });
      };

      return (
        <div className="text-right">
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <DropdownMenuItem>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild className="w-full">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                          <p>Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                        <Switch
                          key={row.original.id}
                          // defaultChecked={isAvailable}
                          defaultChecked={row.original.available}
                          onCheckedChange={toggleAvailability}
                          className="w-11 h-6"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        pizzy
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild className="w-full">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                          <p>50/50</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                        <Switch
                          key={row.original.id}
                          // defaultChecked={isHalfByHalfAvailable}
                          defaultChecked={row.original.isHalfByHalfAvailable}
                          onCheckedChange={async () => {
                            try {
                              await onUpdatePizza({
                                ...row.original,
                                id: row.original.id,
                                // isHalfByHalfAvailable: !isHalfByHalfAvailable,
                                isHalfByHalfAvailable: !row.original.isHalfByHalfAvailable
                              });
                              // setIsHalfByHalfAvailable(!isHalfByHalfAvailable);
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                          className="w-11 h-6"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Określa możliwość zakupu pizzy w wariancie pół na pół
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>

              <AddEditPizza type="update" pizza={row.original} closeDropdown={() => setDropdownOpen(false)} />
              <ConfirmationModal
                title="Usuń"
                description="Usuń pizzę"
                onConfirm={() => deletePizza({ pizzaId: row.original.id! })}
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
