import { Toaster } from '@/components/ui/toaster';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';
import { toast } from './ui/use-toast';

type Props = {
  content: string;
  argName: string;
  trimCallback: (content: string) => string;
};

export const ShortenString = ({ content, argName, trimCallback }: Props) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
    toast({
      title: 'Skopiowano do schowka',
      duration: 1500,
    });
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div onClick={copyToClipboard} className="cursor-pointer">
            {trimCallback(content)}
            <Toaster />
          </div>
        </TooltipTrigger>
        <TooltipContent onClick={copyToClipboard} className="cursor-pointer">
          <p className="text-black/75">
            <b className="font-medium text-black">
              Kliknij, aby skopiować do schowka:
            </b>
            <br />
            {argName}: {content}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
