import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { ReactElement } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type TabValue = 'pizza' | 'skladniki' | 'rozmiary' | 'kategorie-składników';

export const PizzaView = (): ReactElement => {
  const navigate = useNavigate();
  const handleTabClick = (value: TabValue) => navigate(`/pizza/${value}`);
  return (
    <div className="flex flex-col">
      <Tabs defaultValue="pizza" className="w-full">
        <TabsList className="px-10 pt-10 flex">
          <TabsTrigger
            value="pizza"
            onClick={() => handleTabClick('pizza')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Pizza
          </TabsTrigger>
          <TabsTrigger
            value="ingredients"
            onClick={() => handleTabClick('skladniki')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Składniki
          </TabsTrigger>
          <TabsTrigger
            value="sizes"
            onClick={() => handleTabClick('rozmiary')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Rozmiary
          </TabsTrigger>
          <TabsTrigger
            value="categories"
            onClick={() => handleTabClick('kategorie-składników')}
            className="data-[state=active]:bg-slate-200 px-5 py-2 rounded-md"
          >
            Kategorie składników
          </TabsTrigger>
        </TabsList>
        <TabsContent value="pizza">
          <Outlet />
        </TabsContent>
        <TabsContent value="ingredients">
          <Outlet />
        </TabsContent>
        <TabsContent value="sizes">
          <Outlet />
        </TabsContent>
        <TabsContent value="categories">
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  );
};
