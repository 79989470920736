import { api } from './api';

export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type WorkingHour = {
  id: string;
  day: Day;
  from: string;
  to: string;
  createdAt: string;
  updatedAt: string;
};

const baseUrl = `/working-hours`;

const workingHoursApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkingHours: builder.query<{ workingHours: WorkingHour[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['WorkingHours'],
    }),
    updateWorkingHour: builder.mutation<
      { workingHour: WorkingHour },
      {
        workingHourId: WorkingHour['id'];
        fromHours: number;
        fromMinutes: number;
        toHours: number;
        toMinutes: number;
      }
    >({
      query: ({ workingHourId, ...data }) => ({
        method: 'PATCH',
        url: `${baseUrl}/${workingHourId}`,
        body: data,
      }),
      invalidatesTags: ['WorkingHours'],
    }),
  }),
  overrideExisting: false,
});

export const workingHoursEndpoints = workingHoursApi.endpoints;
export const { useGetWorkingHoursQuery, useUpdateWorkingHourMutation } =
  workingHoursApi;
