import { PizzaSizePrice } from '@/api/pizza';
import { Pizza } from './pizza';
import { Product } from './product';

export type Order = {
  id: string;
  status: OrderStatus;
  paymentIntentId: string | null;
  paymentDate: string | null;
  totalPrice: number;
  deliveryType: OrderDeliveryType;
  deliveryPrice: number;
  deliveryDate: string | null;
  email: string;
  phoneNumber: string;
  address: Address;
  businessName: string;
  taxId: string;
  note: string;
  orderItems: OrderItem[];
  createdAt: string;
  updatedAt: string;
};

type Address = {
  street: string;
  postalCode: string;
  city: string;
  flatNumber: string;
  homeNumber: string;
  floor: string;
};

export type OrderItem = {
  id: string;
  name: string;
  quantity: number;
  price: number;
  totalPrice: number;
  description: Record<string, string | number | string[]>;
  pizza: Pizza | null;
  pizzaSize: PizzaSizePrice | null;
  product: Product | null;
  order: Order;
  type: OrderItemType;
  createdAt: string;
  updatedAt: string;
};

export enum OrderStatus {
  NEW = 'new',
  REALIZATION = 'realization',
  IN_DELIVERY = 'in_delivery',
  READY = 'ready',
}

enum OrderDeliveryType {
  COURIER_DELIVERY = 'COURIER_DELIVERY',
  PERSONAL_PICK_UP = 'PERSONAL_PICK_UP',
}

enum OrderItemType {
  PRODUCT = 'PRODUCT',
  PIZZA = 'PIZZA',
  CUSTOM_PIZZA = 'CUSTOM_PIZZA',
  HALF_BY_HALF_PIZZA = 'HALF_BY_HALF_PIZZA',
}
