import {
  Day,
  WorkingHour,
  useGetWorkingHoursQuery,
  useUpdateWorkingHourMutation,
} from '@/api/working-hours';
import { DaysList } from '@/constants/daysList';
import { Loader } from 'lucide-react';
import 'rc-time-picker/assets/index.css';
import { useEffect, useState } from 'react';
import 'react-clock/dist/Clock.css';
import { TimePicker } from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';
import { DialogFooter } from '../ui/dialog';

const orderByDays = (workingHours: WorkingHour[]): WorkingHour[] => {
  const sorted: WorkingHour[] = [];

  for (const day of Object.keys(Day)) {
    const found = workingHours.find((el) => el.day === day);
    if (found) sorted.push(found);
  }

  return sorted;
};

export const WorkingHours = () => {
  const { data } = useGetWorkingHoursQuery();
  const [updateWorkingHour] = useUpdateWorkingHourMutation();

  const [workingHours, setWorkingHours] = useState<WorkingHour[]>(
    data?.workingHours ?? [],
  );

  console.log(data);

  const changeValue = (
    value: string,
    id: WorkingHour['id'],
    origin: 'from' | 'to',
  ) => {
    setWorkingHours((prev) => {
      let filtered = prev.filter((el) => el.id !== id)!;
      let found = prev.find((el) => el.id === id)!;
      if (!found) return filtered;

      const newH = { ...found, [origin]: value };
      return orderByDays([...filtered, newH]);
    });
  };

  useEffect(() => {
    if (!!data?.workingHours?.length)
      setWorkingHours(orderByDays(data.workingHours));
  }, [data]);

  const update = (day: string) => {
    const foundDay = workingHours.find((el) => el.day === day);
    if (!foundDay) return;

    const [fromHours, fromMinutes] = foundDay.from.split(':');
    const [toHours, toMinutes] = foundDay.to.split(':');

    // const [fromHours, fromMinutes] = momentTz(foundDay!.from)
    //   .tz('Europe/Warsaw')
    //   .format('HH:mm')
    //   .split(':');

    // const [toHours, toMinutes] = momentTz(foundDay!.to)
    //   .tz('Europe/Warsaw')
    //   .format('HH:mm')
    //   .split(':');

    updateWorkingHour({
      workingHourId: foundDay.id,
      fromHours: Number(fromHours),
      toHours: Number(toHours),
      fromMinutes: Number(fromMinutes),
      toMinutes: Number(toMinutes),
    });
  };

  return !!workingHours?.length ? (
    <div className="flex flex-col gap-y-4 px-10 pt-2 gap-x-2">
      {workingHours?.map(({ day, from, to, id }) => (
        <div key={day}>
          <label className="text-sm leading-tight font-medium">
            {DaysList[day]}
          </label>
          <div className="flex gap-x-4">
            <div>
              <p className="text-xs leading-tight mb-0.5 mt-1">Od</p>
              {/* <TimePicker
                value={momentTz(from)}
                onChange={(value) => changeValue(value, id, 'from')}
                showSecond={false}
                allowEmpty
              /> */}
              <TimePicker
                format="HH:mm"
                disableClock
                clearIcon={null}
                value={from}
                onChange={(value) =>
                  changeValue(value?.toString() ?? '00:00', id, 'from')
                }
              />
            </div>
            <div>
              <p className="text-xs leading-tight mb-0.5 mt-1">Do</p>
              {/* <TimePicker
                value={momentTz(to)}
                onChange={(value) => changeValue(value, id, 'to')}
                showSecond={false}
                allowEmpty
              /> */}
              <TimePicker
                format="HH:mm"
                disableClock
                clearIcon={null}
                value={to}
                onChange={(value) =>
                  changeValue(value?.toString() ?? '00:00', id, 'to')
                }
              />
            </div>
            <DialogFooter className="sm:items-end">
              <ConfirmationModal
                title="Czy jesteś pewny?"
                description="Zmiana ustawienia spowoduje nieodwracalne zmiany w systemie."
                onConfirm={() => update(day)}
              >
                <Button className="bg-slate-900 text-white">Zapisz</Button>
              </ConfirmationModal>
            </DialogFooter>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Loader />
  );
};
