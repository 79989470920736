import { api } from './api';

export enum SettingsType {
  MAILING = 'MAILING',
  DELIVERY = 'DELIVERY',
  WORKING_HOURS = 'WORKING_HOURS',
  GENERAL = 'GENERAL',
  SUP = 'SUP',
}

export type Setting = {
  id: string;
  name: string;
  value: string;
  type: SettingsType;
  createdAt: string;
  updatedAt: string;
};

const baseUrl = '/settings';

const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettingsByType: builder.query<
      { settings: Setting[] },
      { type: SettingsType; name?: Setting['name'] }
    >({
      query: ({ type, name }) =>
        `${baseUrl}?type=${type}${!!name ? '&name=' + name : ''}`,
      providesTags: ['Setting'],
    }),

    updateSetting: builder.mutation<
      { setting: Setting },
      { settingId: Setting['id']; value: string }
    >({
      query: ({ settingId, value }) => ({
        url: `${baseUrl}/${settingId}`,
        method: 'PATCH',
        body: { value },
      }),
      invalidatesTags: ['Setting'],
    }),
  }),
  overrideExisting: false,
});

export const orderEndpoints = settingsApi.endpoints;
export const { useGetSettingsByTypeQuery, useUpdateSettingMutation } =
  settingsApi;
