import { Size } from '@/types/size';
import { api } from './api';

const baseUrl = '/pizza-sizes';

const sizesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSizes: builder.query<{ pizzaSizes: Size[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Size'],
    }),
    createSize: builder.mutation<
      { pizzaSize: Size },
      { name: Size['name']; description?: Size['description'] }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Size'],
    }),
    updateSize: builder.mutation<
      { pizzaSize: Size },
      {
        name?: Size['name'];
        description?: Size['description'];
        sizeId: Size['id'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.sizeId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Size'],
    }),
    deleteSize: builder.mutation<void, { sizeId: Size['id'] }>({
      query: ({ sizeId }) => ({
        url: `${baseUrl}/${sizeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Size'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = sizesApi.endpoints;
export const {
  useGetSizesQuery,
  useCreateSizeMutation,
  useUpdateSizeMutation,
  useDeleteSizeMutation,
} = sizesApi;
