import { useGetSizesQuery } from '@/api/sizes';
import { DataTable } from '../DataTable';
import { NotFound } from '../NotFound';
import { AddEditSize } from './AddEditSize';
import { columns } from './columns';

export const Sizes = () => {
  const { data, isLoading } = useGetSizesQuery();
  const sizes = data?.pizzaSizes?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista rozmiarów
        </h1>
        {!!sizes && <AddEditSize light />}
      </div>

      {!!sizes ? (
        <DataTable columns={columns} data={data.pizzaSizes} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnego rozmiaru"
          element={<AddEditSize />}
        />
      )}
    </>
  );
};
