import { Product } from '@/types/product';
import { ProductCategory } from '@/types/productCategory';
import { api } from './api';

const baseUrl = '/products';

const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<{ products: Product[] }, void>({
      query: () => `${baseUrl}`,
      providesTags: ['Product'],
    }),
    createProduct: builder.mutation<
      { product: Product },
      {
        name: Product['name'];
        description?: Product['description'];
        available: Product['available'];
        price: Product['price'];
        quantity: Product['quantity'];
        productCategoryId: ProductCategory['id'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Product'],
    }),
    updateProduct: builder.mutation<
      { product: Product },
      {
        productId: Product['id'];
        name: Product['name'];
        description?: Product['description'];
        available: Product['available'];
        price: Product['price'];
        quantity: Product['quantity'];
        productCategoryId: ProductCategory['id'];
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/${body.productId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Product'],
    }),
    deleteProduct: builder.mutation<
      { product: Product },
      { productId: Product['id'] }
    >({
      query: ({ productId }) => ({
        url: `${baseUrl}/${productId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Product'],
    }),
    toggleProductAvailability: builder.mutation<
      { product: Product },
      { productId: Product['id'] }
    >({
      query: ({ productId }) => ({
        url: `${baseUrl}/${productId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Product'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = productsApi.endpoints;
export const {
  useGetProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useToggleProductAvailabilityMutation,
} = productsApi;
