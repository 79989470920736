import { useGetIngredientCategoriesQuery } from '@/api/ingredient-category';
import { DataTable } from '../DataTable';
import { NotFound } from '../NotFound';
import { AddEditIngredientCategory } from './AddEditIngredientCategory';
import { columns } from './columns';

export const IngredientCategories = () => {
  const { data, isLoading } = useGetIngredientCategoriesQuery();
  const ingredientCategories = data?.ingredientCategories?.length;

  return (
    <>
      <div className="flex justify-between items-center mt-12 mb-8 px-10">
        <h1 className="text-2xl font-semibold leading-loose">
          Lista kategorii składników
        </h1>
        {!!ingredientCategories && <AddEditIngredientCategory light />}
      </div>

      {!!ingredientCategories ? (
        <DataTable columns={columns} data={data.ingredientCategories} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnej kategorii składnika"
          element={<AddEditIngredientCategory />}
        />
      )}
    </>
  );
};
