import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loading } from './components/login/Loading';
import { useLogout } from './hooks/useLogout';
import { login } from './slices/credentials/credentialsSlice';
import { useToast } from './components/ui/use-toast';

export const ProtectedRoute = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [isReady, setIsReady] = useState(false);

  const {
    isAuthenticated: auth0IsAuthenticated,
    isLoading: auth0IsLoading,
    getAccessTokenSilently: auth0GetAccessTokenSilently,
  } = useAuth0();

  const { toast } = useToast();
  const dispatch = useDispatch();
  const logout = useLogout();

  useEffect(() => {
    const protect = async () => {
      try {
        const auth0AccessToken = await auth0GetAccessTokenSilently();

        const { data, status } = await axios.post<{ accessToken: string }>(
          `${import.meta.env.VITE_API_URL}/auth/jwt`,
          {
            token: auth0AccessToken,
          },
        );

        if (status !== 201) throw new Error('Unauthorized');

        const { accessToken } = data;
        dispatch(login({ accessToken, auth0AccessToken }));
        setIsReady(true);
      } catch (error: any) {
        // console.log('Could not authenticate', error);
        await logout('unauthorized');
      }
    };

    if (!auth0IsLoading) protect();
  }, [auth0IsLoading]);

  // if (!isLoading && isAuth0Authenticated) {
  //   if (isLoggedIn) return children;

  //   const authPromise = new Promise<string>(async (res) => {
  //     const auth0Token = await getAccessTokenSilently();
  //     res(auth0Token);
  //   });

  //   authPromise.then(async (auth0Token: string) => {
  //     try {
  //       const { data, status } = await axios.post<{ accessToken: string }>(
  //         `${import.meta.env.VITE_API_URL}/auth/jwt`,
  //         {
  //           token: auth0Token,
  //         },
  //       );

  //       if (status !== 201) throw new Error('Unauthorized');
  //       const { accessToken } = data;

  //       dispatch(login({ accessToken, auth0Token }));
  //     } catch (error) {
  //       await logout();
  //     }
  //   });

  //   if (isLoggedIn) return children;
  // }

  // if (!isLoading && !isAuth0Authenticated) logout();

  if (!isReady) return <Loading />;
  return children;
};
